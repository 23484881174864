import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { CustomCheckBox } from "../../../myComponent/CustomCheckBox";

const titleMap = {
  new: "New",
  warm: "Warm",
  reassigned: "Reassign",
  f2fDone: "F2F Done",
  f2fScheduled: "F2F Scheduled",
  videoCallScheduled: "VideoCall Scheduled",
  videoCallDone: "VideoCall Done",
  followUp: "Followup",
  cold: "Cold",
  dnp: "DNP",
  dnpFinal: "DNP Final",
  delay: "Delay",
  notInterested: "Not Interested",
  win: "Win",
  doNotDisturb: "Do Not Disturb",
};

const LeadCountHeader = ({ statusTitle, leadCount }) => {
  return (
    <Box
      bg={"white"}
      py={1}
      borderRadius={8}
      shadow={2}
      border={1}
      borderWidth="0.5px"
      borderColor="#ADADAD"
      display={"flex"}
      whiteSpace={"nowrap"}
      width={"100%"}
      mt={2}
      ml={"6px"}
    >
      <HStack
        cursor={"pointer"}
        fontSize="16px"
        fontWeight="400"
        px={4}
        justifyContent="space-between"
        width={"100%"}
      >
        <Box>
          <CustomCheckBox onClickCheck={(v) => console.log(v)} />
        </Box>
        <Box>
          <Text>
            {leadCount} {titleMap[statusTitle] || ""} Leads
          </Text>
        </Box>
      </HStack>
    </Box>
  );
};

export default LeadCountHeader;
