import React, { useEffect, useMemo, useState } from "react";
import ModalBlur from "../../../components/Leave/ModalBlur";
import { Grid, GridItem, Textarea } from "@chakra-ui/react";
import { CustomInput } from "../../../myComponent/CustomInput";
import DropDown from "../../../components/DropDown/DropDown";
import { CustomText } from "../../../myComponent/CustomText";
import { useFormik } from "formik";
import { leadHeaderItems } from "../../../utils/menuItemCrm";
import { addLeadChangeStatus } from "../../../apiCRM/leadApi";
import { useNavigate } from "react-router-dom";
import { CustomBtn } from "../../../myComponent/CustomBtn";
import { color } from "../../../consts/color";

const ChangeStatus = ({ isOpen, onClose, data, refetch }) => {
  const navigate = useNavigate();

  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const { _id, name, status } = data || {};

  const {
    values,
    setFieldValue,
    handleSubmit,
    resentForm,
    setValues,
    isValid,
  } = useFormik({
    initialValues: {
      name: name || "",
      status: leadHeaderItems?.find((item) => item?.value === status) || [],
      remarkBody: "",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      // console.log("Form data", values);
      setIsLoadingBtn(true);
      // console.log(values);
      const sendData = {
        remarkBody: values?.remarkBody,
        status: values?.status?.value,
        leadId: _id,
      };
      // console.log("sendData", sendData);
      try {
        let res = await addLeadChangeStatus({
          sendData,
        });
        refetch();
        navigate("/lead");
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setIsLoadingBtn(false);
      }
      onClose();
      handleCLose();
    },
  });

  const handleCLose = () => {
    resentForm();
    onClose();
  };

  useEffect(() => {
    if (data) {
      setValues({
        name: data?.name || "",
        status:
          leadHeaderItems?.find((item) => item?.value === data?.status) || {},
        remarkBody: "",
      });
    }
  }, [data, resentForm, setValues]);

  const isStatusChanged = useMemo(
    () => values?.status?.value !== status,
    [values.status.value, status]
  );

  return (
    <ModalBlur
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <>
          <CustomBtn
            title={"Save"}
            isLoading={isLoadingBtn}
            onClick={handleSubmit}
            bgColor={color.primaryBtn}
            containerStyle={{
              marginRight: "1.5rem",
            }}
            isDisabled={!isStatusChanged || !isValid}
          />
        </>
      }
      size="xl"
      maxWidth={{base:'260px',md:'400px'}}
      maxHeight="80vh"
    >
      <Grid templateColumns={{ base: "1fr" }} my={4} gap={6}>
        <GridItem>
          <CustomText
            fontSize="1.7rem"
            textAlign="center"
            my={4}
            fontWeight="bold"
          >
            Change Status
          </CustomText>
        </GridItem>
        <CustomInput
          label={"Lead Name"}
          placeholder="Lead_01"
          name="name"
          value={values?.name}
          disabled="true"
        />
        <DropDown
          label={"Select Status"}
          options={leadHeaderItems}
          getOptionLabel={(option) => option?.label}
          getOptionValue={(option) => option?.value}
          value={values?.status}
          onChange={(v) => {
            // console.log("Status", v?.value);
            setFieldValue("status", v);
          }}
        />
        <GridItem>
          <CustomText fontWeight="semibold" mb={1}>
            Remark
          </CustomText>
          <Textarea
            bg="#F9F9F9"
            name="remarkBody"
            value={values?.remarkBody}
            onChange={(e) => setFieldValue("remarkBody", e.target.value)}
          />
        </GridItem>
      </Grid>
    </ModalBlur>
  );
};

export default ChangeStatus;
