import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const LocalizationProvider = ({ children }) => {
  // Set default timezone (if needed)
  dayjs.tz.setDefault(dayjs.tz.guess());

  return <>{children}</>;
};

export default LocalizationProvider;
