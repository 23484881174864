import React, { useEffect, useState } from "react";
import { Grid, GridItem, Textarea } from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ModalBlur from "../../../components/Leave/ModalBlur";
import LoadButton from "../../../components/LoadButton";
import { CustomInput } from "../../../myComponent/CustomInput";
import { CustomText } from "../../../myComponent/CustomText";
import { color } from "../../../consts/color";
import { CustomBtn } from "../../../myComponent/CustomBtn";
import { useNavigate } from "react-router-dom";
import { leadAddNote } from "../../../apiCRM/leadApi";
import { QueryClient } from "@tanstack/react-query";

const AddNote = ({ isOpen, onClose, data, refetch }) => {
  const navigate = useNavigate();
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const { _id, name } = data || {};

  const {
    values,
    setFieldValue,
    handleSubmit,
    resetForm,
    setValues,
    isValid,
    handleChange,
  } = useFormik({
    initialValues: {
      name: "",
      note: "",
    },
    validationSchema: Yup.object({
      note: Yup.string().required("Note is required"),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      // console.log(values);
      setIsLoadingBtn(true);
      const sendData = {
        leadId: _id,
        note: values.note,
      };
      // console.log("sendData", sendData);
      try {
        let res = await leadAddNote({
          sendData,
        });
        // QueryClient.invalidateQueries({ queryKey: ["leads"] });
        refetch();
        navigate("/lead");
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setIsLoadingBtn(false);
      }

      onClose();
      handleClose();
    },
  });

  const handleClose = () => {
    resetForm();
    onClose();
  };

  useEffect(() => {
    if (data) {
      setValues({
        name: name || "",
        note: "",
      });
    }
  }, [data, resetForm, setValues]);

  return (
    <ModalBlur
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <CustomBtn
          title={"Save"}
          isLoading={isLoadingBtn}
          onClick={handleSubmit}
          bgColor={color.primaryBtn}
          containerStyle={{
            marginRight: "1.5rem",
          }}
          isDisabled={!isValid}
        />
      }
      size="xl"
      maxWidth={{ base: "260px", md: "400px" }}
      minWidth={{ base: "350px", md: "inherit" }}
      maxHeight="80vh"
    >
      <Grid templateColumns={{ base: "1fr" }} my={4} gap={6}>
        <GridItem>
          <CustomText
            fontSize="1.7rem"
            textAlign="center"
            my={4}
            fontWeight="bold"
          >
            Add Note
          </CustomText>
        </GridItem>
        <GridItem>
          <CustomInput
            containerStyle={{
              bg: "gray",
            }}
            label={"Lead Name"}
            // placeholder="Lead_01"
            name="name"
            value={values?.name}
            disabled={true}
          />
        </GridItem>
        <GridItem>
          <CustomText marginBottom={2} fontWeight="500" fontSize={16}>
            Note
          </CustomText>
          <Textarea
            className={color.primaryBtn}
            bg="#F9F9F9"
            name="note"
            value={values.note}
            onChange={(e) => setFieldValue("note", e.target.value)}
            placeholder="Type Here..."
          />
        </GridItem>
      </Grid>
    </ModalBlur>
  );
};

export default AddNote;
