import React, { useEffect, useState } from "react";
import MyContainer from "../../myComponent/MyContainer";
import Filters from "../../components/Filters";
import { CustomBtn } from "../../myComponent/CustomBtn";
import InfiniteScrollList from "../../myComponent/InfiniteScrollList";
import NoDataFound from "../../pages/User/components/NoDataFound";
import { useNavigate } from "react-router-dom";
import CardHeader from "../../components/CardHeader";
import { leadHeaderItems } from "../../utils/menuItemCrm";
import { AddIcon } from "@chakra-ui/icons";
import LeadListItem from "./component/LeadListItem";
import { Box, IconButton, Spinner, useDisclosure } from "@chakra-ui/react";
import { useLeadQuery } from "./useLeadQuery/useLeadQuery";
import AddNote from "./component/AddNote";
import ChangeStatus from "./component/ChangeStatus";
import { useInView } from "react-intersection-observer";
import { useProfileQuery } from "../../Queries/auth/useProfileQuery";
import { adminArr, rolesNotAgentTeam } from "../../utils/menuItems";
import filter from "../../assets/filter.svg";
import FilterAndSort from "../../components/FiltersNew/Filter&Sort";
import LeadFilterSort from "./component/LeadFilterSort";
import CallComponent from "./component/CallComponent";
import LeadCountHeader from "./component/LeadCountHeader";

const LeadList = () => {
  const defaultStatus = "new";
  const [leadStatus, setLeadStatus] = useState(() => {
    return localStorage.getItem("selectedLeadStatus") || defaultStatus;
  });

  const navigate = useNavigate();
  const [leads, setLeads] = useState([]);
  const [search, setSearch] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedLeadData, setSelectedLeadData] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { ref, inView } = useInView();
  const { data: auth } = useProfileQuery();
  const notTeamAgent = rolesNotAgentTeam.includes(auth?.role);
  const [filterState, setFilterState] = useState({
    source: "",
    agentArrFilter: {},
    startCreatedDate: "",
    endCreatedDate: "",
    startAssignedDate: "",
    endAssignedDate: "",
    startUpdatedDate: "",
    endUpdatedDate: "",
    sortOrder: {},
    sortBy: {},
  });

  const [isLoadingData, setIsLoadingData] = useState(false); // For spinner control
  const {
    data: allLead,
    totalItems,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isFetching,
    refetch,
  } = useLeadQuery({
    search,
    status: leadStatus,
    source: filterState?.source?.value,
    sortBy: filterState?.sortBy?.value,
    sortOrder: filterState?.sortOrder?.value,
    startCreatedDate: filterState?.startCreatedDate,
    endCreatedDate: filterState?.endCreatedDate,
    startUpdatedDate: filterState?.startUpdatedDate,
    endUpdatedDate: filterState?.endUpdatedDate,
    startAssignedDate: filterState?.startAssignedDate,
    endAssignedDate: filterState?.endAssignedDate,
    agentArrFilter: JSON.stringify(
      Array.isArray(filterState?.agentArrFilter)
        ? filterState.agentArrFilter.map((item) => item._id)
        : []
    ),
  });

  const handleOpenModal = (type, data) => {
    setModalType(type);
    setSelectedLeadData(data);
    onOpen();
  };

  useEffect(() => {
    refetch();
    localStorage.setItem("selectedLeadStatus", leadStatus);
  }, [leadStatus, refetch, filterState]);

  const buttonConfigs = [
    {
      title: "Reassign",
      actionType: "Reassign",
      onClick: () =>
        navigate("/lead/assign-lead", { state: { actionType: "Reassign" } }),
    },
  ];

  // const userOptions = allLead?.map(lead => lead.name) || [];
  const handleFilterChange = (values) => {
    setIsLoadingData(true);
    setFilterState(values);
    refetch();
  };
  const handleClearFilter = () => {
    setIsLoadingData(true);
    setFilterState({
      source: null,
      agentArrFilter: null,
      startCreatedDate: null,
      endCreatedDate: null,
      startAssignedDate: null,
      endAssignedDate: null,
      startUpdatedDate: null,
      endUpdatedDate: null,
      sortOrder: null,
      sortBy: null,
    });
    refetch();
  };

  useEffect(() => {
    if (!isFetching && !isLoading) {
      setIsLoadingData(false); // Stop spinner when data is fetched
    }
  }, [isFetching, isLoading]);

  return (
    <>
      <MyContainer
        header="Leads"
        subHeader={"Lead List"}
        isheaderWhite
        btnComponent={
          <>
            <Filters onSearchChange={setSearch} />
            <IconButton
              colorScheme="white"
              icon={<img src={filter} alt="Filter" />}
              onClick={() => setIsFilterOpen(true)}
            />
            <AddIcon
              style={{
                backgroundColor: "#9A4D49", // Corrected background color format
                borderRadius: "5px",
                padding: "5px",
                fontSize: "25px",
                color: "white",
                cursor: "pointer",
                fontWeight: "800",
                boxShadow:
                  "black 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
              }}
              onClick={() => navigate("/lead/addlead")}
            />
            {notTeamAgent ? (
              <>
                {buttonConfigs.map((btnConfig, index) => (
                  <CustomBtn
                    key={index}
                    title={btnConfig.title}
                    onClick={btnConfig.onClick}
                  />
                ))}
              </>
            ) : null}
          </>
        }
      >
        <CardHeader
          value={leadStatus}
          items={leadHeaderItems}
          onChange={(v) => setLeadStatus(v)}
          defaultStatus={defaultStatus}
          module="lead"
          containerStyle={{ marginTop: { base: "5rem", md: "inherit" } }}
        />
        <LeadCountHeader statusTitle={leadStatus} leadCount={totalItems} />
        {isLoadingData || isLoading ? (
          <Box textAlign="center" my={4}>
            <Spinner size="md" mt={"10rem"} />
            <p>Loading Leads...</p>
          </Box>
        ) : (
          <InfiniteScrollList
            data={allLead || []}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            isLoading={isLoading}
            isFetching={isFetching}
            renderItem={(item) => (
              <LeadListItem
                item={item}
                onClickBox={() =>
                  navigate("/lead/lead-details", { state: item })
                }
                onClickCheckbox={(v) => console.log("first checkBox", v)}
                onClickCall={(e) => {
                  e.stopPropagation();
                  handleOpenModal("call", item);
                }}
                onClickConversations={(e) => {
                  e.stopPropagation();
                  navigate("/conversation");
                }}
                onClickNotes={(e) => {
                  e.stopPropagation();
                  handleOpenModal("notes", item);
                }}
                onClickMeetings={(e) => {
                  e.stopPropagation();
                  navigate("/meetings/create-meetings", { state: item });
                  console.log("log", item);
                }}
                onStatusChange={(e) => {
                  e.stopPropagation();
                  handleOpenModal("statusChange", item);
                }}
              />
            )}
            loadingMessage="Loading Leads..."
            errorMessage="Error fetching Leads"
            noDataMessage={
              <NoDataFound message="No Lead In The System" name="NoUser" />
            }
            gap={2}
            containerStyle={{ marginTop: "1rem" }}
          />
        )}
        <LeadFilterSort
          isOpen={isFilterOpen}
          onClose={() => setIsFilterOpen(false)}
          onApply={handleFilterChange}
          initialValues={filterState}
          onClear={handleClearFilter}
        />
      </MyContainer>

      {modalType === "notes" && (
        <AddNote
          isOpen={isOpen}
          onClose={onClose}
          data={selectedLeadData}
          refetch={refetch}
        />
      )}
      {modalType === "statusChange" && (
        <ChangeStatus
          isOpen={isOpen}
          onClose={onClose}
          data={selectedLeadData}
          refetch={refetch}
        />
      )}
      {modalType === "call" && (
        <CallComponent
          isOpen={isOpen}
          onClose={onClose}
          data={selectedLeadData}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default LeadList;
