import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import {
  getTeamDetailsById,
  getTeams,
} from "../../../useFunctions/team/teamFunction";
import { getUsers } from "../../../useFunctions/user/userFunctions";

export const useTeamQuery = ({ search, userId, managerArrFilter,
  teamLeadArrFilter,
  startDateCreated,
  endDateCreated,
  startTeamSize,
  endTeamSize,
  sortOrder,
  sortBy }) => {
  let res = useInfiniteQuery({
    queryKey: ["teams", search, userId],
    queryFn: ({ pageParam = 1 }) => getTeams({ pageParam, search, userId, managerArrFilter,
      teamLeadArrFilter,
      startDateCreated,
      endDateCreated,
      startTeamSize,
      endTeamSize,
      sortOrder,
      sortBy }),
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.pagination?.hasNext
        ? parseInt(lastPage?.pagination?.currentPage) + 1
        : undefined;
    },
    staleTime: 1000 * 60 * 10,
  });
  let data = [];
  if (res) {
    data = res?.data?.pages?.map((page) => page?.data).flat();
  }
  return { ...res, data };
};

export const useGetAgent = ({ search }) => {
  let res = useInfiniteQuery({
    queryKey: ["getUsers", "agent", search],
    queryFn: ({ pageParam = 1 }) =>
      getUsers({
        pageParam,
        search,
        status: "approved",
         role: "agent",
      }),
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.pagination?.hasNext
        ? parseInt(lastPage?.pagination?.currentPage) + 1
        : undefined;
    },
    staleTime: 1000 * 60 * 10,
  });
  let data = [];
  if (res) {
    data = res?.data?.pages?.map((page) => page?.data).flat();
  }
  return { ...res, data };
};

export const useGetAgents = ({ search = "", status = "approved", allUsers = false,  role, startDateOfJoining, endDateofJoining, startDateOfCreated, endDateOfCreated, sortBy, sortOrder, }) => {
  return useInfiniteQuery({
    queryKey: ["getUsers", { status, search, allUsers }],
    queryFn: ({ pageParam = 1 }) =>
      getUsers({
        pageParam,
        search,
        status,
        allUsers,
        role, startDateOfJoining, endDateofJoining, startDateOfCreated, endDateOfCreated, sortBy, sortOrder,
      }),
    getNextPageParam: (lastPage) => {
      return lastPage?.pagination?.hasNext
        ? parseInt(lastPage?.pagination?.currentPage) + 1
        : undefined;
    },
    staleTime: 1000 * 60 * 10,
  });
};

export const useGetsAgent = ({ search }) => {
  let res = useInfiniteQuery({
    queryKey: ["getUsers", "agent", search],
    queryFn: ({ pageParam = 1 }) =>
      getUsers({
        pageParam,
        search,
        status: "approved",
        // role: "agent",
      }),
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.pagination?.hasNext
        ? parseInt(lastPage?.pagination?.currentPage) + 1
        : undefined;
    },
    staleTime: 1000 * 60 * 10,
  });
  let data = [];
  if (res) {
    data = res?.data?.pages?.map((page) => page?.data).flat();
  }
  return { ...res, data };
};


export const useGetTeamLead = ({ search,  managerArrFilter,
  teamLeadArrFilter,
  startDateCreated,
  endDateCreated,
  startTeamSize,
  endTeamSize,
  sortOrder,
  sortBy,  }) => {
  let res = useInfiniteQuery({
    queryKey: ["getUsers", "teamLead", search],
    queryFn: ({ pageParam = 1 }) =>
      getUsers({
        pageParam,
        search,
        status: "approved",
        role: "teamLead",
        managerArrFilter,
  teamLeadArrFilter,
  startDateCreated,
  endDateCreated,
  startTeamSize,
  endTeamSize,
  sortOrder,
  sortBy,
      }),
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.pagination?.hasNext
        ? parseInt(lastPage?.pagination?.currentPage) + 1
        : undefined;
    },
    staleTime: 1000 * 60 * 10,
  });
  let data = [];
  if (res) {
    data = res?.data?.pages?.map((page) => page?.data).flat();
  }
  return { ...res, data };
};

export const useGetManager = ({ search, managerArrFilter,
  teamLeadArrFilter,
  startDateCreated,
  endDateCreated,
  startTeamSize,
  endTeamSize,
  sortOrder,
  sortBy, }) => {
  let res = useInfiniteQuery({
    queryKey: ["getUsers", "manager", search],
    queryFn: ({ pageParam = 1 }) =>
      getUsers({
        pageParam,
        search,
        status: "approved",
        role: "manager",
        managerArrFilter,
  teamLeadArrFilter,
  startDateCreated,
  endDateCreated,
  startTeamSize,
  endTeamSize,
  sortOrder,
  sortBy,
      }),
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.pagination?.hasNext
        ? parseInt(lastPage?.pagination?.currentPage) + 1
        : undefined;
    },
    staleTime: 1000 * 60 * 10,
  });
  let data = [];
  if (res) {
    data = res?.data?.pages?.map((page) => page?.data).flat();
  }
  return { ...res, data };
};

export const useGetTeamById = (id) => {
  let res = useQuery({
    queryKey: ["team", id],
    queryFn: () => getTeamDetailsById(id),
    staleTime: 1000 * 60 * 10,
    enabled: !!id,
  });
  return res;
};
