import React, { useState } from "react";
import ModalBlur from "../../../components/Leave/ModalBlur";
import { CustomBtn } from "../../../myComponent/CustomBtn";
import { Grid, GridItem } from "@chakra-ui/react";
import { CustomText } from "../../../myComponent/CustomText";
import { color } from "../../../consts/color";
import { callToLead } from "../../../apiCRM/leadApi";

const CallComponent = ({ isOpen, onClose, data, refetch }) => {
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const handleSubmit = async (values) => {
    // console.log("Form data", values);
    setIsLoadingBtn(true);
    // console.log(values);

    // console.log("sendData", sendData);
    try {
      let res = await callToLead(data?._id);
      //   refetch();
      //   navigate("/lead");
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsLoadingBtn(false);
    }
    onClose();
  };

  return (
    <ModalBlur
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <>
          <CustomBtn
            title={"Call"}
            isLoading={isLoadingBtn}
            onClick={handleSubmit}
            bgColor={color.primaryBtn}
            // containerStyle={{
            //   marginRight: "1.5rem",
            // }}
          />
        </>
      }
      size="xl"
      maxWidth={{ base: "260px", md: "400px" }}
      maxHeight="80vh"
    >
      <Grid templateColumns={{ base: "1fr" }} my={4} gap={6}>
        <GridItem>
          <CustomText
            fontSize="1.2rem"
            textAlign="center"
            // my={4}
            fontWeight="bold"
          >
            {`Do you want to call ${data?.name}`}
          </CustomText>
        </GridItem>
      </Grid>
    </ModalBlur>
  );
};

export default CallComponent;
